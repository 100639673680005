import React from "react";
import styled from "styled-components";

import Genie from "../components/Genie";
import Subheader from "../components/Subheader";
import Button from "../components/Button";

const TinyHabits = ({ machine }) => {
  const [, send] = machine;

  return (
    <Container>
      <Genie />
      <Subheader>
        One more thing! If you think you need some heavy duty help to implement
        your solution, check out the free Tiny Habits program to get matched up
        with a personal coach who will help you design new habits.
      </Subheader>

      <SubContainer>
        <ButtonContainer>
          <Button
            isA="true"
            href="https://www.tinyhabits.com/join"
            target="_blank"
            rel="noopener noreferrer"
          >
            tinyhabits.com/join
          </Button>
        </ButtonContainer>
      </SubContainer>
      <ButtonContainer>
        <Button onClick={() => send("NEXT")}>
          Otherwise, we’re all done for now!
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem 0;
`;

const ButtonContainer = styled.div`
  margin: 1.25rem 0;
`;

export default TinyHabits;
