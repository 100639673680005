import { Machine, assign } from "xstate";

const dashBoardMachine = (email) =>
  Machine(
    {
      id: "dashboard",
      initial: "dashboard",
      context: {
        email: email,
        current: undefined,
      },
      states: {
        dashboard: {
          on: {
            PLANNED: [
              {
                target: "plannedOptions",
                actions: "setCurrent",
              },
            ],
            TO_BE_PLANNED: [
              {
                target: "toBePlannedOptions",
                actions: "setCurrent",
              },
            ],
          },
        },
        plannedOptions: {
          on: {
            BACK: "dashboard",
            PLAN: "plan",
          },
        },
        toBePlannedOptions: {
          on: {
            BACK: "dashboard",
            PLAN: "plan",
          },
        },
        plan: {
          type: "final",
        },
      },
    },
    {
      actions: {
        setCurrent: assign({
          current: (context, event) => event.data,
        }),
      },
    }
  );

export default dashBoardMachine;
