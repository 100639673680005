import React, { useState } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Genie from "../components/Genie";
import Nav from "../components/Nav";
import Subheader from "../components/Subheader";
import Button from "../components/Button";
import { inputStyle } from "../components/Input";

import { api } from "../components/utils";

const putAction = (email, slug, text, reminder_time) => {
  return api
    .put(`/api/user/${email}/actions/solution/${slug}`, { text, reminder_time })
    .then((res) => res.data);
};

const WhenToFollowUp = ({ machine }) => {
  const [current, send] = machine;
  const [startDate, setStartDate] = useState(null);

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { email, currentId, actions } = current.context;
  const currentAction = actions[currentId];

  const submit = async () => {
    setLoading(true);

    const result = await putAction(
      email,
      currentAction.slug,
      currentAction.intentionTextFull,
      startDate
    )
      .then((res) =>
        res._status ? res : setError("Something went wrong. Try again")
      )
      .catch(() => setError("I can't seem to reach the server"));

    setLoading(false);
    result &&
      send({ type: "NEXT", data: { reminderDate: startDate.toISOString() } });
  };

  return (
    <Container>
      <Nav back={() => send("BACK")} />
      <Genie />
      <Subheader>
        {error.length > 0
          ? error
          : "When would you like me to follow up with you to see how things are going?"}
      </Subheader>
      <DateContainer>
        <DatePicker
          placeholderText="Follow up with me..."
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          showTimeSelect
          timeIntervals={15}
          timeCaption="time"
          dateFormat="MMMM d, yyyy h:mm aa"
        />
      </DateContainer>
      <ButtonContainer>
        <Button disabled={isLoading || !startDate} onClick={submit}>
          {isLoading ? "Loading" : "Next"}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 6.25rem;
  margin: 1.25rem 0;
`;

const DateContainer = styled.div`
  input {
    ${inputStyle}
  }
`;

export default WhenToFollowUp;
