import React from "react";
import styled from "styled-components";

import colors from "./colors";

const Button = ({ isA, ...props }) =>
  isA ? <StyledAnchor {...props} /> : <StyledButton {...props} />;
const style = `
  color: ${colors.darkBlue};
  font-family: inherit;
  display: block;
  line-height: 3rem;
  width: 19rem;
  border: none;
  border-radius: 1rem;
  background-color: ${colors.white};
  box-shadow:  1.5rem 1.5rem 3rem ${colors.offWhite},
              -1rem -1rem 3rem ${colors.white};
  box-sizing: border-box;
  vertical-align: middle;
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
  margin: 0;

  transition: transform 0.25s cubic-bezier(0.4, 0.0, 0.2, 1), box-shadow 0.25s cubic-bezier(0.4, 0.0, 0.2, 1);

  text-decoration: none;

  outline: none;
  &::-moz-focus-inner {
    border: 0;
  }

  &:hover {
    transform: translateY(-0.25rem);
  }

  &:disabled {
    box-shadow:  0.1rem 0.1rem 0.5rem ${colors.offWhite},
                -0.1rem -0.1rem 0.5rem ${colors.white};
    background-color: ${colors.offWhite};
    transform: translateY(0.5rem);
    cursor: inherit;
  }
`;

const StyledButton = styled.button`
  ${style}
`;
const StyledAnchor = styled.a`
  ${style}
`;

export default Button;
