import React from "react";
import styled from "styled-components";

import Genie from "../components/Genie";
import Nav from "../components/Nav";
import Header from "../components/Header";
import Subheader from "../components/Subheader";
import Button from "../components/Button";

import { lowerFirst } from "../components/utils";

const AddToCalendar = ({ machine }) => {
  const [current, send] = machine;

  const { solutions, actions, currentId } = current.context;

  const currentSolution = solutions.filter((x) => x.id === currentId)[0];

  const title = currentSolution.title;

  const intention = `${title.replace("your", "my")} ${lowerFirst(
    actions[currentId].timeText
  )} ${lowerFirst(actions[currentId].locationText)}`;

  const calendarLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURI(
    intention
  )}&details=${encodeURI(actions[currentId].actionText)}&sf=true&output=xml`;

  return (
    <Container>
      <Nav back={() => send("BACK")} />
      <Genie />
      <Header>Sweet! You said your intention is to:</Header>
      <Subheader>"{intention}"</Subheader>

      <SubContainer>
        <Subheader>
          Take a moment to add this to your calendar or task list so you don’t
          forget
        </Subheader>
        <ButtonContainer>
          <Button
            isA="true"
            href={calendarLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Open in Google Calendar
          </Button>
        </ButtonContainer>
      </SubContainer>
      <ButtonContainer>
        <Button
          onClick={() =>
            send({
              type: "NEXT",
              data: {
                intentionText: title,
                intentionTextFull: intention,
              },
            })
          }
        >
          Ok, done
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem 0;
`;

const ButtonContainer = styled.div`
  margin: 1.25rem 0;
`;

export default AddToCalendar;
