import React, { useState } from "react";
import styled from "styled-components";

import Genie from "../components/Genie";
import Nav from "../components/Nav";
import Header from "../components/Header";
import Subheader from "../components/Subheader";
import Button from "../components/Button";
import Subbutton from "../components/Subbutton";

import { api, lowerFirst } from "../components/utils";

const Options = ({ machine }) => {
  const [state, send] = machine;
  const [isLoading, setLoading] = useState(false);
  const [deleteIsLoading, setDeleteLoading] = useState(false);
  const [doneLoading, setDoneLoading] = useState(false);
  const [error, setError] = useState("");

  const { email, current } = state.context;

  const deleteAction = (email, slug) => {
    return api
      .delete(`/api/user/${email}/actions/solution/${slug}`)
      .then((res) => res.data);
  };

  const markActionAsDone = (email, slug) => {
    return api
      .patch(`/api/user/${email}/actions/solution/${slug}/done`, {
        is_done: true,
      })
      .then((res) => res.data);
  };

  const submitDelete = async (slug) => {
    setLoading(true);
    setDeleteLoading(true);

    await deleteAction(email, slug)
      .then((res) => {
        if (res._status) {
          send("BACK");
        } else {
          setError("Something went wrong. Try again");
        }
      })
      .catch(() =>
        setError("Yikes! I can't seem to reach our servers. Try again?")
      );

    setLoading(false);
    setDeleteLoading(false);
  };

  const submitDone = async (slug) => {
    setLoading(true);
    setDoneLoading(true);

    await markActionAsDone(email, slug)
      .then((res) => {
        if (res._status) {
          send("BACK");
        } else {
          console.log(res);
          setError("Something went wrong. Try again");
        }
      })
      .catch(() =>
        setError("Yikes! I can't seem to reach our servers. Try again?")
      );

    setLoading(false);
    setDoneLoading(false);
  };

  const ToBePlanned = () => {
    return (
      <Container>
        {isLoading || <Nav back={() => send("BACK")} />}
        <Genie />
        <Header>{error || current.title}</Header>
        <ButtonContainer>
          <Button disabled={isLoading} onClick={() => send("PLAN")}>
            Plan action
          </Button>
          {
            //
            // NOTE: API doesn't seem to support deleting solutions from cart
            // <Subbutton disabled={isLoading} onClick={submitDelete}>
            //   {deleteIsLoading ? "Loading" : "Delete"}
            // </Subbutton>
          }
        </ButtonContainer>
      </Container>
    );
  };

  const Planned = () => {
    return (
      <Container>
        {isLoading || <Nav back={() => send("BACK")} />}
        <Genie />
        <Header>{error || current.title}</Header>
        <Subheader>You said: "I will {lowerFirst(current.text)}"</Subheader>
        <ButtonContainer>
          <Button
            disabled={isLoading}
            onClick={() => submitDone(current.slug)}
          >
            {doneLoading ? "Loading" : "Mark as completed"}
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => send("PLAN")}
          >
            {doneLoading ? "Loading" : "Change plan"}
          </Button>
          <Subbutton
            disabled={isLoading}
            onClick={() => submitDelete(current.slug)}
          >
            {deleteIsLoading ? "Loading" : "Remove plan"}
          </Subbutton>
        </ButtonContainer>
      </Container>
    );
  };

  switch (state.value) {
    default:
      return <Planned />;
    case "toBePlannedOptions":
      return <ToBePlanned />;
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  * {
    margin: 1.25rem 0 0;
    box-shadow: none;
  }
`;

export default Options;
