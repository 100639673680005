import React, { useState } from "react";
import styled from "styled-components";

import Genie from "../components/Genie";
import Nav from "../components/Nav";
import Subheader from "../components/Subheader";
import Button from "../components/Button";
import TextArea from "../components/TextArea";

import { lowerFirst } from "../components/utils";

const WhatActions = ({ machine }) => {
  const [current, send] = machine;
  const [actionText, setActionText] = useState("");

  const { solutions, currentId } = current.context;

  const currentSolution = solutions.filter((x) => x.id === currentId)[0];

  const title = currentSolution.title;
  const slug = currentSolution.slug;

  return (
    <Container>
      <Nav back={() => send("BACK")} />
      <Genie />
      <Subheader>
        In order to {lowerFirst(title)}, what specific actions do you need to
        take?
      </Subheader>
      <Subheader>Type them out in the box below</Subheader>
      <TextArea
        placeholder="I need to..."
        onChange={(e) => setActionText(e.target.value)}
      />
      <ButtonContainer>
        <Button
          disabled={actionText.length === 0}
          onClick={() => send({ type: "NEXT", data: { slug, actionText } })}
        >
          Next
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 6.25rem;
  margin: 1.25rem 0;
`;

export default WhatActions;
