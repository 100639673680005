import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Genie from "../components/Genie";
import Header from "../components/Header";
import Subheader from "../components/Subheader";
import Button from "../components/Button";
import colors from "../components/colors";

import { api } from "../components/utils";

const getActions = (email) => {
  return api.get(`/api/user/${email}/actions`).then((res) => res.data);
};

const getCart = (email) => {
  return api.get(`/api/user/${email}/cart`).then((res) => res.data);
};

const Dashboard = ({ machine }) => {
  const [current, send] = machine;
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [actions, setActions] = useState(null);
  const [cart, setCart] = useState(null);

  const { email } = current.context;

  const submit = async () => {
    setLoading(true);

    await Promise.all([getActions(email), getCart(email)])
      .then((res) => {
        const actionsRes = res[0];
        const cartRes = res[1];

        if (actionsRes._status && cartRes._status) {
          setActions(actionsRes.actions);
          setCart(cartRes.solutions);
        } else {
          setError("Something went wrong. Try again");
        }
      })
      .catch(() =>
        setError("Yikes! I can't seem to reach our servers. Try again?")
      );

    setLoading(false);
  };

  useEffect(() => {
    submit();
  }, []);

  // Filter out actions marked as done
  const filteredActions = (actions || []).filter((a) => !a.is_done);
  // Filter out everything but completed actions
  const completedActions = (actions || []).filter((a) => a.is_done);
  // Filter out the planned solutions
  const filteredCart = (cart || []).filter(
    (s) =>
      (actions || []).filter((a) => a.solution.slug === s.slug).length === 0
  );

  const Actions = () => {
    return (
      <>
        {filteredActions.length > 0 && (
          <ActionsContainer>
            <Subheader>Planned</Subheader>
            {filteredActions.map((action, i) => (
              <Button
                key={i}
                onClick={() =>
                  send({
                    type: "PLANNED",
                    data: { ...action.solution, text: action.text },
                  })
                }
              >
                {action.solution.title}
              </Button>
            ))}
            <br />
          </ActionsContainer>
        )}

        {filteredCart.length > 0 && (
          <ActionsContainer>
            <Subheader>To be planned</Subheader>
            {filteredCart.map((solution, i) => (
              <Button
                key={i}
                onClick={() => send({ type: "TO_BE_PLANNED", data: solution })}
              >
                {solution.title}
              </Button>
            ))}
            <br />
          </ActionsContainer>
        )}

        {completedActions.length > 0 && (
          <ActionsContainer>
            <Subheader>Completed</Subheader>
            {completedActions.map((action, i) => (
              <Button key={i}>{action.solution.title}</Button>
            ))}
            <br />
          </ActionsContainer>
        )}
      </>
    );
  };

  return (
    <Container>
      <Genie />
      <Header>{error || "Dashboard"}</Header>
      {isLoading ? (
        <Subheader style={{ marginTop: "1.25rem" }}>Loading</Subheader>
      ) : (
        <Actions />
      )}
      <a
        style={{ color: colors.darkBlue, marginTop: "1.25rem" }}
        href="https://docs.google.com/forms/d/e/1FAIpQLScujVz5BoBRL_bSjDlh7DTig-pVZXzCke4krEaVtXskgMF8Tg/viewform?usp=sf_link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Submit feedback to the Screentime Genie team
      </a>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ActionsContainer = styled.div`
  * {
    margin: 1.25rem 0 0;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
`;

export default Dashboard;
