import styled from "styled-components";

import colors from "./colors";

const Header = styled.h1`
  color: ${colors.darkBlue};
  font-size: 2.25rem;
  font-weight: 600;
  margin: 0 0 1.25rem 0;
  padding: 0;
`;

export default Header;
