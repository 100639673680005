import React from "react";
import styled from "styled-components";
import { useMachine } from "@xstate/react";

import routingMachine from "./machines/routingMachine.js";
import actionsMachine from "./machines/actionsMachine.js";

import Welcome from "./views/Welcome";
import GoToStg from "./views/GoToStg";
import Login from "./views/Login";
import CheckEmail from "./views/CheckEmail";
import LoggedIn from "./views/LoggedIn";

const App = () => (
  <StyledApp>
    <Container>
      <Router />
    </Container>
  </StyledApp>
);

const Router = () => {
  const machine = useMachine(routingMachine);
  const [current] = machine;

  switch (current.value) {
    default:
      return <Welcome machine={machine} />;
    case "goToStg":
      return <GoToStg machine={machine} />;
    case "login":
      return <Login machine={machine} />;
    case "checkEmail":
      return <CheckEmail machine={machine} />;
    case "loggedIn":
      return <LoggedIn machine={machine} actionsMachine={actionsMachine} />;
  }
};

const StyledApp = styled.div`
  max-width: 23.4375rem;
  margin: 0 auto;
  text-align: center;
`;

const Container = styled.div`
  padding: 5rem 0 0 0;
`;

export default App;
