import React from "react";
import styled from "styled-components";

import Nav from "../components/Nav";
import Genie from "../components/Genie";
import Subheader from "../components/Subheader";
import Button from "../components/Button";

const GoToStg = ({ machine }) => {
  const [, send] = machine;

  return (
    <Container>
      <Nav back={() => send("BACK")} />
      <Genie />
      <Subheader>
        Go to screentime.stanford.edu first to get solutions from me, then come
        back.
      </Subheader>
      <ButtonContainer>
        <Button
          isA="true"
          href="https://screentime.stanford.edu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Open in browser
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  margin: 1.25rem 0;
`;

export default GoToStg;
