import React from "react";
import styled from "styled-components";

import Genie from "../components/Genie";
import Header from "../components/Header";

import colors from "../components/colors";

const WorkOn = ({ machine }) => {
  const [current, send] = machine;

  const { solutions, actions } = current.context;

  return (
    <Container>
      <Genie />
      <Header>Which solution would you like to work on?</Header>
      <ImageContainer>
        {(solutions || []).map(
          (sol, i) =>
            (actions.hasOwnProperty(sol.id) && actions[sol.id].done) || ( // Don't display solutions that have already been selected
              <div onClick={() => send({ type: "NEXT", data: sol.id })} key={i}>
                <img alt={sol.title} src={sol.image_urlpath} />
              </div>
            )
        )}
      </ImageContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ImageContainer = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  * {
    width: 100%;
  }
  div {
    border-radius: 1rem;
    margin: 2.5rem 0;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 1.5rem 1.5rem 3rem ${colors.offWhite},
      -1.5rem -1.5rem 3rem ${colors.white};
    &:hover {
      transform: translateY(-0.25rem);
      box-shadow: 2rem 2rem 4rem ${colors.offWhite},
        -2rem -2rem 4rem ${colors.white};
    }
  }
`;

export default WorkOn;
