import React, { useState } from "react";
import styled from "styled-components";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import Genie from "../components/Genie";
import Header from "../components/Header";
import Subheader from "../components/Subheader";
import Button from "../components/Button";
import { inputStyle } from "../components/Input";

import { api } from "../components/utils";

const patchPhone = (email, phone) => {
  return api.patch(`/api/user/${email}`, { phone }).then((res) => res.data);
};

const PhoneNumber = ({ machine }) => {
  const [state, send] = machine;
  const { email, actions } = state.context;

  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const submit = async () => {
    setLoading(true);

    const result = await patchPhone(email, phoneNumber)
      .then((res) =>
        res._status ? res : setError("Something went wrong. Try again")
      )
      .catch(() => setError("I can't seem to reach the server"));

    setLoading(false);
    result && send({ type: "NEXT", data: { phoneNumber } });
  };

  return (
    <Container>
      <Genie />
      <Header>{error || "Where should I send you reminders?"}</Header>
      <Subheader>I’ll only send you messages at the times you set</Subheader>
      <InputContainer>
        <PhoneInput
          defaultCountry="US"
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={setPhoneNumber}
        />
      </InputContainer>
      <ButtonContainer>
        <Button
          disabled={isLoading || !isPossiblePhoneNumber(phoneNumber)}
          onClick={submit}
        >
          {isLoading ? "Loading" : "Next"}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 6.25rem;
  margin: 1.25rem 0;
`;

const InputContainer = styled.div`
  margin: 1.25rem 0;
  input {
    ${inputStyle}
    margin-right: 2.3rem;
  }
`;

export default PhoneNumber;
