import React from "react";
import styled from "styled-components";

import Subbutton from "../components/Subbutton";

const Nav = ({ back }) => (
  <StyledNav>
    <Subbutton onClick={back}>{"<- Back"}</Subbutton>
  </StyledNav>
);

const StyledNav = styled.nav`
  position: absolute;
  width: 100%;
  max-width: 23.4375rem;
  padding: 0 1rem;
  box-sizing: border-box;
  top: 1.5rem;
  display: flex;
  align-items: flex-start;
`;

export default Nav;
