import React from "react";
import { useMachine } from "@xstate/react";

import Loading from "./Loading";
import WelcomeBack from "./WelcomeBack";
import WorkOn from "./WorkOn";
import WhatActions from "./WhatActions";
import WhatTime from "./WhatTime";
import WhereWillYouBe from "./WhereWillYouBe";
import AddToCalendar from "./AddToCalendar";
import WhenToFollowUp from "./WhenToFollowUp";
import AddMore from "./AddMore";
import PhoneNumber from "./PhoneNumber";
import TinyHabits from "./TinyHabits";
import DashboardRouter from "./DashboardRouter";

const LoggedIn = ({ machine, actionsMachine }) => {
  const [{ context }] = machine;

  const newMachine = useMachine(actionsMachine(context.email));
  // const newMachine = useMachine(actionsMachine("test@mailinator.com"));

  const [current] = newMachine;

  switch (current.value) {
    default:
      return <Loading />;
    case "welcome":
      return <WelcomeBack machine={newMachine} />;
    case "workOn":
      return <WorkOn machine={newMachine} />;
    case "whatActions":
      return <WhatActions machine={newMachine} />;
    case "whatTime":
      return <WhatTime machine={newMachine} />;
    case "whereWillYouBe":
      return <WhereWillYouBe machine={newMachine} />;
    case "addToCalendar":
      return <AddToCalendar machine={newMachine} />;
    case "whenToFollowUp":
      return <WhenToFollowUp machine={newMachine} />;
    case "addMore":
      return <AddMore machine={newMachine} />;
    case "phoneNumber":
      return <PhoneNumber machine={newMachine} />;
    case "tinyHabits":
      return <TinyHabits machine={newMachine} />;
    case "dashboard":
      return <DashboardRouter machine={newMachine} />;
  }
};

export default LoggedIn;
