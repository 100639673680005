import React, { useState } from "react";
import styled from "styled-components";

import Genie from "../components/Genie";
import Nav from "../components/Nav";
import Header from "../components/Header";
import Subheader from "../components/Subheader";
import Button from "../components/Button";
import Input from "../components/Input";

const WhatTime = ({ machine }) => {
  const [, send] = machine;
  const [timeText, setActionText] = useState("");
  const [other, setOther] = useState(false);

  const next = (a) => send({ type: "NEXT", data: { timeText: a } });

  switch (other) {
    default:
      return (
        <Container>
          <Nav back={() => send("BACK")} />
          <Genie />
          <Header>When will you take the first step?</Header>
          <Subheader>(Right now is a great choice!)</Subheader>
          <TimeContainer>
            <Button onClick={() => next("Right now")}>Right now!</Button>
            <Button onClick={() => next("When I get home today")}>
              When I get home today
            </Button>
            <Button onClick={() => next("Before bed tonight")}>
              Before bed tonight
            </Button>
            <Button onClick={() => next("First thing tomorrow")}>
              First thing tomorrow
            </Button>
            <Button onClick={() => setOther(true)}>Other</Button>
          </TimeContainer>
        </Container>
      );
    case true:
      return (
        <Container>
          <Nav back={() => send("BACK")} />
          <Genie />
          <Header>When will you take the first step?</Header>

          <InputContainer>
            <Input
              placeholder="I'll do it..."
              onChange={(e) => setActionText(e.target.value)}
            />
          </InputContainer>
          <ButtonContainer>
            <Button
              disabled={timeText.length === 0}
              onClick={() => next(timeText)}
            >
              Next
            </Button>
          </ButtonContainer>
        </Container>
      );
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TimeContainer = styled.div`
  * {
    margin: 1.25rem 0 0;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 6.25rem;
  margin: 1.25rem 0;
`;

const InputContainer = styled.div`
  margin: 1.25rem 0;
`;

export default WhatTime;
