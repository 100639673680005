import React, { useState } from "react";
import styled from "styled-components";

import Nav from "../components/Nav";
import Mailbox from "../components/Mailbox";
import Header from "../components/Header";
import Subheader from "../components/Subheader";
import Input from "../components/Input";
import Button from "../components/Button";

import { auth0Domain, auth0ClientId } from "../components/utils";
import colors from "../components/colors";

const CheckEmail = ({ machine }) => {
  const [current, send] = machine;
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [code, setCode] = useState("");

  const queryParams = {
    grant_type: "http://auth0.com/oauth/grant-type/passwordless/otp",
    client_id: auth0ClientId,
    realm: "email",
    username: current.context.email,
    otp: code,
  };

  const authUrl = `${auth0Domain}/oauth/token`;

  const checkCode = async () => {
    // Structure the auth parameters and URL
    setLoading(true);

    fetch(authUrl, {
      body: JSON.stringify(queryParams),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);

        if (res.error) {
          setError("Sorry, that code isn't correct");
        } else {
          send("NEXT");
        }
      })
      .catch(() => {
        setError("I can't seem to reach the server");
        setLoading(false);
      });
  };

  return (
    <Container>
      <Nav back={() => send("BACK")} />
      <Mailbox />
      <Header>{error.length > 0 ? error : "Check your email"}</Header>
      <Subheader>We sent you a secret code to help you log in</Subheader>
      <BottomContainer>
        <Input
          placeholder="Enter code here"
          onChange={(e) => setCode(e.target.value)}
        />
        <ButtonContainer>
          <Button
            disabled={isLoading || code.length === 0}
            onClick={checkCode}
          >
            {isLoading ? "Loading" : "Next"}
          </Button>
          <p style={{ color: colors.darkBlue, opacity: 0.6 }}>
            The email may be in your spam folder
          </p>
        </ButtonContainer>
      </BottomContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 2.5rem 0 0 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 7.5rem;
  margin: 1.25rem 0;
`;

export default CheckEmail;
