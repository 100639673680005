import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Genie from "../components/Genie";
import Header from "../components/Header";

const Loading = () => {
  return (
    <Container>
      <Genie />
      <Header>Loading...</Header>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default Loading;
