import { Machine, assign } from "xstate";
import { api } from "../components/utils";
import axios from "axios";

const dashboardPlanMachine = (currentSolution) => (email) =>
  Machine(
    {
      id: "dashboardPlan",
      initial: "whatActions",
      context: {
        email: email,
        currentId: currentSolution.id,
        solutions: [currentSolution],
        actions: {},
      },
      states: {
        whatActions: {
          on: {
            BACK: "dashboard",
            NEXT: [
              {
                target: "whatTime",
                actions: "storeAction",
              },
            ],
          },
        },
        whatTime: {
          on: {
            BACK: "whatActions",
            NEXT: [
              {
                target: "whereWillYouBe",
                actions: "storeAction",
              },
            ],
          },
        },
        whereWillYouBe: {
          on: {
            BACK: "whatTime",
            NEXT: [
              {
                target: "addToCalendar",
                actions: "storeAction",
              },
            ],
          },
        },
        addToCalendar: {
          on: {
            BACK: "whereWillYouBe",
            NEXT: [
              {
                target: "whenToFollowUp",
                actions: "storeAction",
              },
            ],
          },
        },
        whenToFollowUp: {
          on: {
            NEXT: [
              {
                target: "sms",
                actions: "storeAction",
              },
            ],
          },
        },
        sms: {
          invoke: {
            id: "schedule-reminders",
            src: invokeScheduleReminders,
            onDone: "dashboard",
            onError: {
              target: "dashboard",
            },
          },
        },
        dashboard: {
          type: "final",
        },
      },
    },
    {
      actions: {
        storeSolutions: assign({
          solutions: (context, event) => event.solutions,
        }),
        storeAction: assign({
          actions: (context, event) => {
            const newActions = {
              ...context.actions,
              [context.currentId]: {
                ...context.actions[context.currentId],
                ...event.data,
              },
            };

            return newActions;
          },
        }),
      },
    }
  );

const getInfo = (email) => {
  return api.get(`/api/user/${email}`).then((res) => res.data);
};

const scheduleReminder = ({
  phone_number,
  email,
  name,
  intention,
  slug,
  date,
}) => {
  return axios("https://screentime.stanford.edu/api/reminder", {
    crossdomain: true,
    method: "post",
    data: {
      phone_number,
      email,
      name,
      intention,
      slug,
      date,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  }).then((res) => res.data);
};

const scheduleReminders = ({ email, phone, name, actions }) => {
  const res = Promise.all(
    Object.keys(actions).map((i) => {
      const { intentionText, slug, reminderDate } = actions[i];
      return scheduleReminder({
        phone_number: phone,
        email,
        name,
        intention: intentionText,
        slug,
        date: reminderDate,
      });
    })
  );
  return res;
};

const invokeScheduleReminders = async (context, event) => {
  return new Promise(async (resolve, reject) => {
    const info = await getInfo(context.email);
    const remindersRes = await scheduleReminders({
      email: context.email,
      phone: info.phone,
      name: info.name,
      actions: context.actions,
    });
    if (info._status && remindersRes.reduce((x, y) => x && y)._status) {
      resolve(info);
    } else {
      reject();
    }
  });
};

export default dashboardPlanMachine;
