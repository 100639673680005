import React from "react";
import styled from "styled-components";

import colors from "./colors";

const style = `
  color: ${colors.darkBlue};
  opacity: 0.6;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0 0 1.25rem 0;
  padding: 0;
`;

const StyledLabel = styled.label`
  ${style}
`;
const StyledH2 = styled.h2`
  ${style}
`;

const Subheader = ({ labelFor, ...props }) =>
  labelFor ? (
    <StyledLabel htmlFor={labelFor} {...props} />
  ) : (
    <StyledH2 {...props} />
  );

export default Subheader;
