import styled from "styled-components";

import colors from "./colors";
import { rgba } from "./utils";

export const inputStyle = `
  &:disabled {
    color: ${rgba(colors.darkBlue, 0.3)};
  }

  color: ${colors.darkBlue};
  font-family: inherit;
  height: 3rem;
  width: 19rem;
  border: 0.1rem solid ${rgba(colors.darkBlue, 0.3)};
  border-radius: 1rem;
  background: none;
  box-shadow: inset 0.1rem 0.1rem 0.2rem ${colors.offWhite},
              inset -0.1rem -0.1rem 0.2rem ${colors.white};
  font-size: 1rem;
  padding: 0;
  margin: 0;

  transition: all 0.25s cubic-bezier(0.4, 0.0, 0.2, 1);

  outline: none;
  &::-moz-focus-inner {
    border: 0;
  }

  box-sizing: border-box;
  padding: 0 0.5rem;

  ::placeholder {
    color: ${colors.darkBlue};
    opacity: 0.5;
  }

  -webkit-appearance: none;

  /* Disable number arrows */
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }

`;

const Input = styled.input`
  ${inputStyle}
`;

export default Input;
