import React from "react";
import styled from "styled-components";

import Genie from "../components/Genie";
import Header from "../components/Header";
import Subheader from "../components/Subheader";
import Button from "../components/Button";
import Subbutton from "../components/Subbutton";
import colors from "../components/colors";

const Welcome = ({ machine }) => {
  const [, send] = machine;

  return (
    <Container>
      <Genie />
      <Header>Welcome to the Screentime Genie Accountability Coach</Header>
      <Subheader>Are you coming from the Screentime Genie Website?</Subheader>
      <ButtonContainer>
        <Button onClick={() => send("YES")}>Yes I am</Button>
        <Subbutton onClick={() => send("NO")}>No I'm not</Subbutton>
      </ButtonContainer>
      <br />
      <a
        style={{ color: colors.darkBlue, marginTop: "1.25rem" }}
        href="https://docs.google.com/forms/d/e/1FAIpQLScujVz5BoBRL_bSjDlh7DTig-pVZXzCke4krEaVtXskgMF8Tg/viewform?usp=sf_link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Submit feedback to the Screentime Genie team
      </a>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 6.25rem;
  margin: 1.25rem 0;
`;

export default Welcome;
