import React, { useState } from "react";
import styled from "styled-components";

import Genie from "../components/Genie";
import Nav from "../components/Nav";
import Header from "../components/Header";
import Button from "../components/Button";
import Input from "../components/Input";

const WhereWillYouBe = ({ machine }) => {
  const [, send] = machine;
  const [locationText, setLocationText] = useState("");
  const [other, setOther] = useState(false);

  const next = (a) => send({ type: "NEXT", data: { locationText: a } });

  switch (other) {
    default:
      return (
        <Container>
          <Nav back={() => send("BACK")} />
          <Genie />
          <Header>Where will you be?</Header>
          <LocationContainer>
            <Button onClick={() => next("By my front door")}>
              By my front door
            </Button>
            <Button onClick={() => next("At my desk")}>At my desk</Button>
            <Button onClick={() => next("In my bedroom")}>In my bedroom</Button>
            <Button onClick={() => setOther(true)}>Other</Button>
          </LocationContainer>
        </Container>
      );
    case true:
      return (
        <Container>
          <Nav back={() => send("BACK")} />
          <Genie />
          <Header>Where will you be?</Header>
          <Input
            placeholder="I'll be..."
            onChange={(e) => setLocationText(e.target.value)}
          />
          <ButtonContainer>
            <Button
              disabled={locationText.length === 0}
              onClick={() => send({ type: "NEXT", data: { locationText } })}
            >
              Next
            </Button>
          </ButtonContainer>
        </Container>
      );
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 6.25rem;
  margin: 1.25rem 0;
`;

const LocationContainer = styled.div`
  * {
    margin: 1.25rem 0 0;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
`;

export default WhereWillYouBe;
