import React from "react";
import styled from "styled-components";

import Genie from "../components/Genie";
import Subheader from "../components/Subheader";
import Button from "../components/Button";

const AddMore = ({ machine }) => {
  const [, send] = machine;
  return (
    <Container>
      <Genie />
      <Subheader>
        Great! Would you like to plan for more solutions now or add them later?
      </Subheader>

      <SubContainer>
        <ButtonContainer>
          <Button
            onClick={() => send({ type: "ANOTHER", data: { done: true } })}
          >
            I'll add another
          </Button>
        </ButtonContainer>
      </SubContainer>
      <ButtonContainer>
        <Button onClick={() => send("NEXT")}>
          I'm good for now. I'll do it later
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem 0;
`;

const ButtonContainer = styled.div`
  margin: 1.25rem 0;
`;

export default AddMore;
