import React, { useState } from "react";
import styled from "styled-components";

import Nav from "../components/Nav";
import Sun from "../components/Sun";
import Subheader from "../components/Subheader";
import Input from "../components/Input";
import Button from "../components/Button";

import { api, auth0Domain, auth0ClientId, isEmail } from "../components/utils";

const emailExists = (email) => {
  return api.get(`/api/user/${email}`).then((res) => res.data._status);
};

const Login = ({ machine }) => {
  const [, send] = machine;

  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const submit = async () => {
    setLoading(true);

    const result = await emailExists(email)
      .then(
        (res) =>
          res || setError("Sorry, we couldn't find an account with that email")
      )
      .catch(() =>
        setError(
          "I can't seem to reach the server. Is your internet connected?"
        )
      );

    setLoading(false);
    result && next();
  };

  const next = () => {
    send({ type: "NEXT", email });

    const authUrl = `${auth0Domain}/passwordless/start`;

    const queryParams = {
      connection: "email",
      client_id: auth0ClientId,
      email: email,
      send: "code", };

    fetch(authUrl, {
      body: JSON.stringify(queryParams),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    });
  };

  return (
    <Container>
      <Nav back={() => send("BACK")} />
      <Sun />
      <Subheader>
        {error.length > 0
          ? error
          : "Great! Proceed when you are serious about putting your solutions into action."}
      </Subheader>

      <BottomContainer>
        <Subheader labelFor="email">
          Which email did you use on the website?
        </Subheader>
        <InputContainer>
          <Input
            disabled={isLoading}
            autoComplete="off"
            type="email"
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email: "
          />
        </InputContainer>
        <Button
          disabled={isLoading || !isEmail(email)}
          onClick={() => submit()}
        >
          {isLoading ? "Loading" : "Next"}
        </Button>
      </BottomContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 2.5rem 0;
`;

const InputContainer = styled.div`
  margin: 1.25rem 0;
`;

export default Login;
