import { Machine, assign } from "xstate";

import { isEmail } from "../components/utils";

const emailValid = (context, { email }) => {
  return isEmail(email);
};

const setEmail = assign({
  email: (context, event) => event.email,
});

const routingMachine = Machine({
  id: "routing",
  initial: "welcome",
  context: {
    email: undefined,
  },
  states: {
    welcome: {
      on: {
        YES: "login",
        NO: "goToStg",
      },
    },
    goToStg: {
      on: {
        BACK: "welcome",
      },
    },
    login: {
      on: {
        BACK: "welcome",
        NEXT: [
          {
            target: "checkEmail",
            cond: emailValid,
            actions: [setEmail],
          },
        ],
      },
    },
    checkEmail: {
      on: {
        BACK: "login",
        NEXT: "loggedIn",
      },
    },
    loggedIn: {
      type: "final",
    },
  },
});

export default routingMachine;
