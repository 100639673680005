import React, { useState } from "react";
import styled from "styled-components";

import Genie from "../components/Genie";
import Header from "../components/Header";
import Subheader from "../components/Subheader";
import Button from "../components/Button";

import { api } from "../components/utils";

const getActions = (email) => {
  return api.get(`/api/user/${email}/actions`).then((res) => res.data);
};

const getSolutions = (email) => {
  return api.get(`/api/user/${email}/cart`).then((res) => res.data);
};

const WelcomeBack = ({ machine }) => {
  const [current, send] = machine;
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const email = current.context.email;

  const submit = async () => {
    setLoading(true);

    await Promise.all([getSolutions(email), getActions(email)])
      .then((res) => {
        const solutionsRes = res[0];
        const actionsRes = res[1];

        if (solutionsRes._status && actionsRes._status) {
          const filteredActions = (actionsRes.actions || []).filter(
            (a) => !a.is_done
          );
          if (filteredActions.length > 0) {
            send("DASHBOARD");
          } else {
            send({ type: "NEXT", solutions: solutionsRes.solutions });
          }
        } else {
          setError("I wasn't able to find your account");
          setLoading(false);
        }
      })
      .catch(() => {
        setError("Yikes! I can't seem to reach our servers. Try again?");
        setLoading(false);
      });
  };

  return (
    <Container>
      <Genie />
      <Header>{error.length > 0 ? error : "Hi, welcome back!"}</Header>
      <Subheader>
        I’m here to help put your chosen solutions into action.
      </Subheader>
      <ButtonContainer>
        <Button disabled={isLoading} onClick={submit}>
          {isLoading ? "Loading" : "Let's get started"}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 6.25rem;
  margin: 1.25rem 0;
`;

export default WelcomeBack;
