import styled from "styled-components";

import colors from "./colors";

const Subbutton = styled.button`
  background: none;
  border: none;
  color: ${colors.darkBlue};
  font-family: inherit;
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
  margin: 0;

  outline: none;
  &::-moz-focus-inner {
    border: 0;
  }

  &:hover {
    opacity: 0.5;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export default Subbutton;
