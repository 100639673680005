import React from "react";
import { useMachine } from "@xstate/react";

import dashboardMachine from "../machines/dashboardMachine.js";
import dashboardPlanMachine from "../machines/dashboardPlanMachine.js";

import Dashboard from "./Dashboard";
import Options from "./Options";
import LoggedIn from "./LoggedIn";

const DashboardRouter = ({ machine }) => {
  const [{ context }] = machine;

  const newMachine = useMachine(dashboardMachine(context.email));

  const [current] = newMachine;

  switch (current.value) {
    default:
      return <Dashboard machine={newMachine} />;
    case "plannedOptions":
      return <Options machine={newMachine} />;
    case "toBePlannedOptions":
      return <Options machine={newMachine} />;
    case "plan":
      return (
        <LoggedIn
          machine={newMachine}
          actionsMachine={dashboardPlanMachine(current.context.current)}
        />
      );
  }
};

export default DashboardRouter;
