import React from "react";
import TextAreaAutosize from "react-textarea-autosize";
import styled from "styled-components";

import colors from "./colors";
import { rgba } from "./utils";

const TextArea = (props) => (
  <Wrapper>
    <TextAreaAutosize minRows={3} {...props} />
  </Wrapper>
);

const Wrapper = styled.div`
  textarea {
    &:disabled {
      color: ${rgba(colors.darkBlue, 0.3)};
    }

    overflow: hidden;
    color: ${colors.darkBlue};
    font-family: inherit;
    width: 19rem;
    border: 0.1rem solid ${rgba(colors.darkBlue, 0.3)};
    border-radius: 1rem;
    background: none;
    box-shadow: inset 0.1rem 0.1rem 0.2rem ${colors.offWhite},
      inset -0.1rem -0.1rem 0.2rem ${colors.white};
    font-size: 1rem;
    margin: 0;

    outline: none;
    &::-moz-focus-inner {
      border: 0;
    }

    box-sizing: border-box;
    padding: 0.5rem;

    ::placeholder {
      color: ${colors.darkBlue};
      opacity: 0.5;
    }

    -webkit-appearance: none;

    /* Disable number arrows */
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
`;

export default TextArea;
